<template>
    <div class="testPaperCenterSty">
        <div class="contentSty">
            <div class="cardTitle">
                <div>
					<span style="color:#FFFFFF">
						题型 : {{ worngBookDetails.questionType | formatQuestionType }}
					</span>
                    <span style="padding: 0 5px;color:#FFFFFF;">|</span>
                    <span style="padding: 0 5px;color:#FFFFFF">答题时间 : {{ worngBookDetails.createTime }}</span>
                </div>

                <!--<div class="cardButton">

                    <span @click="openAnalysisDialog()">
                        解析补充
                    </span>
                    <span @click="openAmendDialog()">
                        纠错
                    </span>
                    <span @click="collectQuestion()">
                        收藏
                    </span>
                    &lt;!&ndash;                    <span @click="deleteWrongRecord(worngBookDetails.id)">&ndash;&gt;
                    &lt;!&ndash;                        删除&ndash;&gt;
                    &lt;!&ndash;                    </span>&ndash;&gt;
                </div>-->
            </div>

            <radioSubject v-if="(questionBody.questionType == 0) || (questionBody.questionType == 1) "
                          :subjectId="subjectId"
                          :questionBody="questionBody" :haveAnswer="true" :isDis="true"
                          style="border-radius:  0 0 4px 4px;"
                          :chooseItem="questionBody.answerInfo.content">
            </radioSubject>
            <checkSubject v-if="questionBody.questionType == 2" :subjectId="subjectId" :questionBody="questionBody"
                          :haveAnswer="true" :isDis="true"
            >
            </checkSubject>
            <shortAnswerSubject v-if="questionBody.questionType == 3" :subjectId="subjectId"
                                :questionBody="questionBody"
                                :haveAnswer="true" :isDis="true">
            </shortAnswerSubject>
            <programmingSubject v-if="questionBody.questionType == 4" :subjectId="subjectId"
                                :questionBody="questionBody" :isDis="true" :haveAnswer="true">
            </programmingSubject>
            <programmingSubjectMore v-if="questionBody.questionType == 5" :subjectId="subjectId"
                                    :questionBody="questionBody" :isDis="true" :haveAnswer="true">
            </programmingSubjectMore>
        </div>

        <amendDialog :amendDialogShow="amendDialogShow"
                     :questionId="this.questionReadVM.id"
                     @closeAmendDialog="closeAmendDialog"
        ></amendDialog>

        <analysisDialog :analysisDialogShow="analysisDialogShow" :questionId="this.questionReadVM.id"
                        @closeAnalysisDialog="closeAnalysisDialog"></analysisDialog>
    </div>
</template>

<script>
    import radioSubject from "@/components/radioSubject.vue"
    import checkSubject from "@/components/checkSubject.vue"
    import shortAnswerSubject from "@/components/shortAnswerSubject.vue"
    import programmingSubject from "@/components/programmingSubject.vue"
    import programmingSubjectMore from "@/components/programmingSubjectMore.vue"
    import amendDialog from "@/components/amendDialog.vue"
    import analysisDialog from "@/components/analysisDialog.vue"
    import {
        checkCountByAnswerPaperAndDay,
        deleteWrongBookQuestion,
        wrongBookDetails
    } from "@/api/index.js"

    export default {
        filters: {
            formatQuestionType(type) {
                switch (type) {
                    case 0:
                        return "单选题";
                        break;
                    case 1:
                        return "判断题";
                        break;
                    case 2:
                        return "多选题";
                        break;
                    case 3:
                        return "简答题";
                        break;
                    case 4:
                        return "编程题";
                        break;
                    case 5:
                        return "填空题";
                        break;
                    default:
                        break;
                }
            }
        },
        props: {
            worngBookDetails: {
                type: Object,
                default: () => ({})
            },
            subjectId: {
                type: Number,
                default: 1
            }
        },
        components: {
            radioSubject,
            checkSubject,
            shortAnswerSubject,
            programmingSubject,
            programmingSubjectMore,
            analysisDialog,
            amendDialog
        },
        mounted() {
            let param = {
                id: this.worngBookDetails.id
            }
            wrongBookDetails(param).then(res => {
                if (res.code === "0000") {
                    this.questionReadVM = res.data.questionReadVM
                    this.answerRequest = res.data.answerRequest
                    this.questionBody = res.data.questionReadVM
                    this.questionBody.answerInfo = res.data.answerRequest
                }
            }).catch(() => {
                this.$router.push({
                    path: '/'
                })
            });
        },

        data() {
            return {
                value1: 4.5,
                questionReadVM: {},
                answerRequest: {},
                questionBody: {},
                amendDialogShow: false,
                analysisDialogShow: false
            }
        },
        methods: {

            openAmendDialog() {
                this.amendDialogShow = true;
            },
            closeAmendDialog() {
                this.amendDialogShow = false;
            },
            openAnalysisDialog() {
                this.analysisDialogShow = true;
            },
            closeAnalysisDialog() {
                this.analysisDialogShow = false;
            },
            collectQuestion() {
                this.$message({
                    type: 'success',
                    message: '收藏成功！'
                });
            }


            // deleteWrongRecord(id) {
            //     this.$confirm('此操作将移除该题, 是否继续?', '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //     }).then(() => {
            //         deleteWrongBookQuestion(id).then(res => {
            //             if (res.code === "0000") {
            //                 this.$emit('delete-form-list', id);
            //             } else {
            //                 this.$message({
            //                     message: res.message,
            //                     type: 'warning'
            //                 });
            //             }
            //         })
            //         this.$message({
            //             type: 'success',
            //             message: '删除成功!'
            //         });
            //     }).catch(() => {
            //         this.$message({
            //             type: 'info',
            //             message: '已取消删除'
            //         });
            //     });
            //
            // },
        }
    }
</script>

<style lang="scss" scoped>
    .testPaperCenterSty {
        width: 1200px;
        margin-top: 20px;
        min-height: 300px;
        position: relative;


        .contentSty {
            margin-top: 20px;
            color: #ffffff;

            .cardTitle {
                border-radius: 4px 4px 0 0;
                background-color: #409EFF;
                font-size: 13px;
                color: #ffffff;
                height: 30px;
                line-height: 30px;
                padding-left: 20px;
                padding-right: 30px;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: space-between;

                .cardButton {
                    span {
                        padding-left: 20px;
                        color: #FFFFFF;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

    }
</style>

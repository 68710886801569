<template>
    <div class="page" style="min-height: 64.5vh;">

        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right" class="page-breadcrumb">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>错题本</el-breadcrumb-item>
            </el-breadcrumb>
            <mainSelect :showPopup="1"
                        :selectPaperNameByHome="questionName"
                        @reset-select="resetWrongBookList"
                        @new-select-submit-date="selectBySubmitDateAndWrong"
                        @new-select-question-type="selectByQuestionTypeAndWrong"
                        @send-selected-to-parent="getTestPaperBySelectAndWrong"
                        @new-select-context="selectWrongByQuestionTitle">

            </mainSelect>
            <div v-loading="loading">
                <div v-if="worngBook.length === 0">
                    <el-empty description="太棒了，完全正确无误！" style="margin-top: 50px;background-color: #fff"></el-empty>
                </div>
                <div v-else>
                    <div v-for="(item,index) in worngBook" :key="item.id">
                        <wrongBookDetails :worngBookDetails="item" :subjectId="index + 1"
                        ></wrongBookDetails>
                    </div>

                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="currentPage" :page-sizes="[5,10, 20, 30, 50]" :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                               style="margin-top: 20px;text-align: center">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import mainSelect from "@/components/mainSelect.vue"
    import wrongBookDetails from "./components/wrongBookDetails.vue"
    import {
        wrongBookList
    } from "@/api/index.js"

    export default {
        components: {
            mainSelect,
            wrongBookDetails
        },
        data() {
            return {
                loading: false,
                worngBook: [],
                allInfo: {
                    total: 0
                },
                nowType: {},
                currentPage: 1,
                pageSize: 5,
                haveMore: false,
                noMore: false,
                isLoading: true,
                questionName: '',
                matchParent: 'ALL',
                langParent: 'ALL',
                levelParent: 'ALL',
                createTime: null,
                questionType: null
            };
        },
        watch: {
            questionName: {
                handler: function (n, o) {
                    if (n !== undefined) {
                        this.selectWrongBookList();
                    }
                }
            },

        },
        methods: {

            resetData() {
                this.loading = false;
                this.worngBook = [];
                this.allInfo = {
                    total: 0
                };
                this.currentPage = 1;
                this.pageSize = 5;
                this.haveMore = false;
                this.noMore = false;
                this.isLoading = true;
                this.matchParent = 'ALL';
                this.langParent = 'ALL';
                this.levelParent = 'ALL';
                this.createTime = null;
                this.questionType = null;
                this.questionName = "";
                this.questionType = null;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.selectWrongBookList()
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.selectWrongBookList()
            },
            resetWrongBookList() {
                this.questionName = "";
                this.resetData();
                this.selectWrongBookList();
            },
            selectBySubmitDateAndWrong(val) {
                this.createTime = val;
                this.selectWrongBookList();
            },
            selectByQuestionTypeAndWrong(val) {
                this.questionType = val;
                this.selectWrongBookList();
            },
            getTestPaperBySelectAndWrong(data) {
                this.matchParent = data.selectedMatch;
                this.langParent = data.selectedLang;
                this.levelParent = data.selectedLevel;

                this.selectWrongBookList();
            },
            selectWrongByQuestionTitle(val){
                this.questionName = val
            },
            selectWrongBookList() {
                this.loading = true;
                let param = {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    match: this.matchParent,
                    lang: this.langParent,
                    level: this.levelParent,
                    questionName: this.questionName,
                    questionType: this.questionType,
                    createTime: this.createTime
                }
                wrongBookList(param).then(res => {
                    if (res.code === "0000") {
                        this.allInfo = res.data;
                        this.worngBook = res.data.list;
                        this.loading = false;
                        this.scrollToTop();
                    }
                }).catch(() => {
                    this.loading = false;
                    // this.$router.push({
                    // 	path: '/'
                    // })
                });
            },
            scrollToTop() {
                // 使用 window.scrollTo 方法将页面滚动到顶部
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // 平滑滚动效果
                });
            }
        },
        mounted() {
            this.selectWrongBookList();
            // this.getInfo()
            // let _this = this;
            // window.onscroll = function() {
            // 	//变量scrollTop是滚动条滚动时，距离顶部的距离
            // 	var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // 	//变量windowHeight是可视区的高度
            // 	var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            // 	//变量scrollHeight是滚动条的总高度
            // 	var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            // 	//滚动条到底部的条件
            // 	if (scrollTop + windowHeight == scrollHeight) {
            // 		//到了这个就可以进行业务逻辑加载后台数据了
            // 		// _this.footerText = '我是有底线的';
            // 		if (_this.allInfo.total > _this.worngBook.length) {
            // 			if (_this.isLoading) {
            // 				_this.isLoading = false
            // 				_this.haveMore = true
            // 				_this.currentPage = _this.currentPage + 1
            // 				setTimeout(() => {
            // 					console.log("2")
            // 					_this.getInfo()
            // 				}, 1000);
            // 				console.log(_this.worngBook)
            // 				console.log("到了底部");
            // 			}
            // 		} else {
            // 			_this.haveMore = false
            // 			_this.noMore = true
            // 		}
            // 	}
            // }
        }
    };
</script>

<style scoped>
    .page-breadcrumb {
        padding: 15px 0;
        cursor: pointer;
    }
</style>
